<template>
  <b-modal
    hide-footer
    v-model="showModal"
    size="lg"
    title="Register CRM"
    centered
  >
    <div>
      <b-tabs
        class="tabs-main"
        nav-class="nav-item-customs"
        content-class="p-3"
        v-model="tabIndex"
      >
        <b-tab title="1. Personal Info" active>
          <b-row>
            <b-col md>
              <InputText
                v-model="form.firstName"
                textFloat="ชื่อจริง"
                placeholder="ชื่อจริง"
                isRequired
                id="firstName"
                type="text"
                :v="$v.form.firstName"
              />
            </b-col>
            <b-col md v-if="brandId == fixBrandId">
              <InputText
                v-model="form.middleName"
                textFloat="ชื่อกลาง"
                placeholder="ชื่อกลาง"
                id="middlename"
                type="text"
                :v="$v.form.middleName"
              />
            </b-col>
            <b-col md>
              <InputText
                v-model="form.lastName"
                textFloat="นามสกุล"
                placeholder="นามสกุล"
                isRequired
                id="lastName"
                type="text"
                :v="$v.form.lastName"
              />
            </b-col>
          </b-row>
          <b-row v-if="brandId == fixBrandId">
            <b-col md="6">
              <InputText
                v-model="form.email"
                textFloat="อีเมล"
                placeholder="อีเมล"
                isRequired
                id="email"
                type="text"
                :v="$v.form.email"
              />
            </b-col>
            <b-col md="6">
              <InputDateTimePicker
                v-model="form.birthday"
                textFloat="วันเกิด"
                placeholder="วันเกิด"
                id="birthday"
                type="date"
                format="DD/MM/YYYY"
              />
            </b-col>
          </b-row>
          <b-row v-if="brandId == fixBrandId">
            <b-col md="6">
              <InputText
                v-model="form.idcard"
                textFloat="เลขบัตรประชาชน"
                placeholder="เลขบัตรประชาชน"
                id="idcard"
                type="text"
              />
            </b-col>
            <b-col md="6">
              <InputText
                v-model="form.passportId"
                textFloat="หมายเลขหนังสือเดินทาง"
                placeholder="หมายเลขหนังสือเดินทาง"
                id="passportId"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <InputTextTelephone
                ref="inputTextTelephone"
                v-model="form.telephone"
                textFloat="เบอร์โทรศัพท์"
                placeholder="เบอร์โทรศัพท์"
                id="telephone"
                type="text"
                :list="countryCodeList"
                @onChangeDropdown="onChangeCountryCodeId"
              />
            </b-col>
          </b-row>
        </b-tab>
        <!-- <b-tab title="Second"><p>I'm the second tab</p></b-tab> -->
      </b-tabs>
      <div class="mt-3 d-flex w-100">
        <div class="mx-auto">
          <b-button @click="hide" variant="outline-primary" class="mr-1"
            >ยกเลิก</b-button
          >
          <b-button
            @click="save"
            variant="main-primary"
            class="ml-1"
            :disabled="isLoading"
            >บันทึก</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextTelephone from "@/components/inputs/InputTextTelephone";
import InputDateTimePicker from "@/components/inputs/InputDateTimePicker";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
// import { Datetime } from "vue-datetime";

export default {
  props: {
    userChatInfo: {
      type: Object,
      required: false
    },
    crmDetail: {
      type: Object,
      required: false
    },
    chatRoomId: {
      type: [String],
      required: false
    }
  },
  components: {
    InputText,
    InputTextTelephone,
    InputDateTimePicker
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      form: {
        firstName: "",
        middleName: "",
        lastName: "",
        telephone: "",
        countryCodeId: "67343faf657d1f711b623d7c",
        isConsent: 0,
        userId: "",
        email: "",
        birthday: "",
        idcard: "",
        passportId: ""
      },
      tabIndex: 0,
      countryCodeList: [
        {
          code: "+66",
          countryCodeId: "67343faf657d1f711b623d7c",
          name: "Thailand"
        }
      ],
      fixBrandId: 105 // เปิดเพาะ bdms ไปก่อน ในอนาคตต้องเอาโค้ดนี้ออกนะ
    };
  },
  validations() {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        email: {
          email,
          required: requiredIf(() => {
            return this.brandId == this.fixBrandId;
          })
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      brandId: "getBrand"
    })
  },
  created() {
    this.getCountryCode();
  },
  methods: {
    init() {
      this.$v.form.$reset();
      this.form = {
        firstName: "",
        middleName: "",
        lastName: "",
        telephone: "",
        countryCodeId: "67343faf657d1f711b623d7c",
        isConsent: 0,
        userId: "",
        email: "",
        birthday: "",
        idcard: "",
        passportId: ""
      };
    },
    show() {
      this.init();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async onChangeCountryCodeId(item) {
      this.form.countryCodeId = item.id;
    },
    getCountryCode() {
      this.$axios
        .get(`${this.$chatBaseUrl}/setting/telephone/code`)
        .then(response => response.data)
        .then(res => {
          if (res.result == 1) {
            this.countryCodeList = res.detail;
          }
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.$store.dispatch("setIsLoading", true);
      let body = {
        firstName: this.form.firstName,
        middleName: this.form.middleName,
        lastName: this.form.lastName,
        telephone: this.form.telephone,
        countryCodeId: this.form.countryCodeId,
        isConsent: 0,
        userId: this.userChatInfo.externalId,
        email: this.form.email,
        birthday:
          this.form.birthday === "" ||
          !this.form.birthday ||
          /Invalid|NaN/.test(new Date(this.form.birthday))
            ? null
            : this.form.birthday,
        idcard: this.form.idcard,
        passportId: this.form.passportId
      };
      await this.$axios
        .post(`${this.$chatBaseUrl}/crm/customer/create`, body)
        .then(response => response.data)
        .then(async res => {
          if (res.result == 1) {
            this.isLoading = false;
            this.$store.dispatch("setIsLoading", false);
            this.$store.commit("setMessageGlobalAlert", "บันทึกข้อมูลสำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            await this.$emit("get-detail");
            this.hide();
          } else {
            this.isLoading = false;
            this.$store.dispatch("setIsLoading", false);
            this.$store.commit("setMessageGlobalAlertError", res.message);
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .btn-main-primary,
::v-deep .btn-outline-primary {
  min-width: 150px !important;
}
@media (max-width: 767.98px) {
  ::v-deep .btn-main-primary,
  ::v-deep .btn-outline-primary {
    min-width: unset !important;
  }
}
</style>
