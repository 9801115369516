<template>
  <div :class="['panel']">
    <div v-if="isError" class="reload-chat">
      <div class="text-center">
        <p>Network error!!</p>
        <button class="btn btn-primary btn-sm p-1" @click="prepareData">
          Reload
        </button>
      </div>
    </div>
    <div v-if="!isError && !isShowProfileOrder" class="h-100">
      <div class="loading-prepare" v-if="isPrepare">
        <div class="spinner-border text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else :class="['card-box', { 'show-mark': isDisplayMark }]">
        <div
          :class="[
            'card-box-header-profile',
            { 'show-search': isDisplayPanelSearch }
          ]"
        >
          <div
            class="disabled-panel"
            v-if="displayCheckboxSelectUserChat"
          ></div>
          <div
            class="d-flex align-items-center bd-highlight bg-white panel-header-profile"
          >
            <div
              v-if="!isShowBoth && $route.query.isChatBox != 'true'"
              class="img_back mr-2"
              @click="$emit('show-list-chat')"
            >
              <font-awesome-icon
                :icon="['fas', 'chevron-left']"
                class="img_back_icon"
              />
            </div>
            <div class="img_cont" @click="setActivePanel(3)">
              <div v-if="otherInfo.imageUrl" class="rounded-circle user_img">
                <div
                  class="rounded-circle user_img_display"
                  v-bind:style="{
                    backgroundImage: 'url(' + otherInfo.imageUrl + ')'
                  }"
                ></div>
              </div>
              <font-awesome-icon
                v-else
                :icon="['fas', 'user']"
                class="rounded-circle user_img"
              />
            </div>
            <div class="user_info" @click="setActivePanel(3)">
              <div class="d-flex">
                <span class="name">{{
                  $store.state.displayNameCurrentChat
                    ? $store.state.displayNameCurrentChat
                    : otherInfo.displayname
                }}</span>
                <b-button
                  variant="copy-clipboard"
                  v-b-tooltip.hover
                  :title="tooltipText"
                  @click.stop.prevent="copyClipboard"
                  @mouseout="outCopyClipboard"
                  class="ml-1"
                >
                  <font-awesome-icon icon="copy" />
                </b-button>
              </div>

              <div class="tag-social">
                <img
                  v-if="chatSocialType"
                  :src="chatSocialType.imageUrl"
                  alt=""
                />
                <span v-if="chatSocialType" class="mb-0">{{
                  chatSocialType.name
                }}</span>
              </div>
            </div>
            <div class="ml-auto">
              <b-button
                variant="outline-primary"
                class="btn-transfer-agent"
                @click="handleShowSideberFilterHistory"
              >
                Chat History
              </b-button>
            </div>
            <div class="ml-2">
              <b-button
                variant="outline-primary"
                class="btn-transfer-agent"
                @click="handleShowModalAssignTo"
                :disabled="disabledAssignTo"
              >
                Assign To
              </b-button>
            </div>
            <div class="ml-2">
              <b-button variant="search" @click="setDisplaySearch(true)">
                <font-awesome-icon icon="search" />
              </b-button>
            </div>
          </div>
          <div v-if="isDisplayPanelSearch" class="panel-search">
            <InputText
              v-model="filterConversation.search"
              placeholder="Search Chat History"
              type="text"
              icon="search"
              @submit="submitSearchChatHistory"
              @onkeyup="handleKeyupSearchChatHistory"
            />
            <span class="total">
              {{ selectedNumberMarkText | numeral("0,0") }}/{{
                totalMarkText | numeral("0,0")
              }}</span
            >
            <b-button
              variant="arrow"
              :disabled="selectedNumberMarkText <= 1"
              @click="handleFocusTextMark('up')"
            >
              <font-awesome-icon
                icon="chevron-up"
                :class="['arrow', { disabled: selectedNumberMarkText <= 1 }]"
              />
            </b-button>
            <b-button
              variant="arrow"
              :disabled="selectedNumberMarkText >= totalMarkText"
              @click="handleFocusTextMark('down')"
            >
              <font-awesome-icon
                icon="chevron-down"
                :class="[
                  'arrow',
                  { disabled: selectedNumberMarkText >= totalMarkText }
                ]"
              />
            </b-button>
            <font-awesome-icon icon="times" @click="setDisplaySearch(false)" />
          </div>
        </div>
        <!-- <div v-if="!isShowBoth" class="card-box-header msg_head">
          <div class="d-flex bd-highlight">
            <div
              v-if="$route.query.isChatBox != 'true'"
              class="img_back mr-3"
              @click="$emit('show-list-chat')"
            >
              <font-awesome-icon
                :icon="['fas', 'chevron-left']"
                class="img_back_icon"
              />
            </div>
            <div class="img_cont">
              <div v-if="otherInfo.imageUrl" class="rounded-circle user_img">
                <div
                  class="rounded-circle user_img_display"
                  v-bind:style="{
                    backgroundImage: 'url(' + otherInfo.imageUrl + ')'
                  }"
                ></div>
              </div>
              <font-awesome-icon
                v-else
                :icon="['fas', 'user']"
                class="rounded-circle user_img"
              />
            </div>
            <div class="user_info">
              <span>{{ otherInfo.displayname }}</span>
            </div>
            <div class="ml-auto">
              <b-button
                variant="link"
                class="pr-0"
                @click="handleOpenProfileSideBar(true)"
                v-if="isShowProfileTab"
                >Order</b-button
              >
            </div>
          </div>
        </div> -->
        <div
          v-if="displayCheckboxSelectUserChat"
          class="selected-chat d-flex align-items-center"
        >
          <div class="text-center">
            <p class="selected-count">
              {{ selectedUserChatList.length || 0 }} Chat Selected
            </p>
            <div class="wrap-img-agent">
              <div
                v-for="(agentChat, lidx) in selectedUserChatList.slice(0, 5)"
                :key="`${agentChat.id}-${lidx}`"
                class="rounded-circle img-agent img-agent-size mb-1"
                :id="`tooltip-agent-${lidx}`"
              >
                <span>{{ Array.from(agentChat.name)[0] }}</span>
                <b-tooltip
                  :target="`tooltip-agent-${lidx}`"
                  triggers="hover"
                  variant="light"
                  placement="bottomright"
                >
                  {{ agentChat.name }}
                </b-tooltip>
              </div>
              <div
                v-if="selectedUserChatList.length > 5"
                class="rounded-circle img-agent img-agent-size mb-1"
              >
                <span> <font-awesome-icon icon="plus" size="xs" /> </span>
              </div>
            </div>
            <p class="detail-count">
              Resolve, set properties or a bulk reply to all in a shot
            </p>
          </div>
        </div>
        <div v-else-if="isNoConversation" class="panel-wellcome-chat">
          <div class="text-center">
            <div class="wrap-other-chat-img mb-2 mx-auto">
              <div
                class="other-chat-img rounded-circle"
                v-bind:style="{
                  backgroundImage: 'url(' + otherInfo.photoUrl + ')'
                }"
              ></div>
            </div>
            <p class="mb-0">พูดคุยกับ {{ otherInfo.name }}</p>
          </div>
        </div>
        <div v-else-if="isLoadingSearch" class="panel-loading text-center">
          <img
            src="@/assets/images/loading-d-dots.gif"
            alt="loading"
            class="loading-icon"
          />
        </div>
        <div v-else class="wrpa-msg_card_body">
          <div
            v-if="isLoadingConversation && conversation.data !== null"
            class="loading-message w-100 text-center"
          >
            <div class="spinner-border text-secondary bg-none" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div
            v-show="conversation.data && conversation.data !== null"
            :class="[
              'msg_card_body',
              { 'have-public': profileNote?.public?.note }
            ]"
            id="chatMsgCardBody"
          >
            <ChatBoxMessageList
              :messageList="conversation.data.slice().reverse()"
              :meProfile="meProfile"
              :otherInfo="otherInfo"
              ref="ChatBoxMessageList"
            />
            <!-- <ChatBoxMessageList
              :messageList="dummyMessageList"
              :meProfile="meProfile"
              :otherInfo="otherInfo"
            /> -->
          </div>
        </div>
        <div class="panel-wrap-card-box-footer px-3 pt-2">
          <div
            class="disabled-panel"
            v-if="displayCheckboxSelectUserChat"
          ></div>
          <div class="wrap-card-box-footer">
            <div v-if="isShowTranslate" class="pb-2">
              <ChatBoxTranslateFlex
                ref="ChatBoxTranslateFlex"
                :text="textInput"
                :isLoadingTranslate="isLoadingTranslate"
                :textTranslated="textTranslated"
              />
            </div>
            <b-list-group
              v-if="!showSetReply && !isShowTranslate"
              class="list-reply"
            >
              <b-list-group-item
                class="list-item-reply"
                v-for="(choice, idx) in choiceReply"
                :key="`reply-${choice.id}-${idx}`"
              >
                <div class="d-flex flex-row">
                  <div
                    class="d-flex flex-row w-75"
                    @click="handleConfirmTextReply(choice)"
                  >
                    <div class="w-25 reply-key">
                      <p class="font-weight-bold">/{{ choice.shortkeys }}</p>
                    </div>
                    <div class="w-75 pl-2">
                      <b-badge
                        pill
                        variant="badge-public"
                        v-if="choice.type == 1"
                        >Public</b-badge
                      >
                      <b-badge
                        pill
                        variant="badge-private"
                        v-else-if="choice.type == 2"
                        >Private</b-badge
                      >
                      <p class="mb-0">{{ choice.title }}</p>
                      <div
                        class="div-textReply text-wrap text-black-50"
                        v-if="choice.replyType.indexOf(1) > -1"
                      >
                        {{ choice.textReply }}
                      </div>
                      <div v-if="choice.replyType.indexOf(2) > -1">
                        <div class="w-25 mb-1" v-if="choice.imageReply">
                          <PreviewFile :urlPath="choice.imageReply" />
                        </div>
                      </div>
                      <div
                        class="div-textReply text-wrap text-black-50"
                        v-if="choice.replyType.indexOf(4) > -1"
                      >
                        {{ choice.linkReply }}
                      </div>
                      <div class="display-time text-wrap text-black-50">
                        <p>
                          Created:
                          <ChatDateTime
                            class="time"
                            :date="choice.createdTime"
                          />
                          by
                          {{ choice.createdByName || "-" }}
                        </p>
                        <p>
                          Last Updated:
                          <ChatDateTime
                            class="time"
                            :date="choice.updatedTime"
                          />
                          by
                          {{ choice.updatedByName || "-" }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="w-25 d-flex justify-content-end">
                    <a
                      class="w-50 d-flex justify-content-end menu-reply"
                      @click="handleShowEditReply(choice)"
                      >แก้ไข</a
                    >
                    <a
                      class="w-50 d-flex justify-content-end menu-reply"
                      @click="delReply(choice.id, idx)"
                      >ลบ</a
                    >
                  </div>
                </div></b-list-group-item
              >
            </b-list-group>
            <div class="edit-reply" v-if="showSetReply && !isShowTranslate">
              <div class="pt-2 pl-2 pr-2">
                <font-awesome-icon
                  icon="chevron-left"
                  class="back-edit-reply"
                  @click="handleShowEditReply"
                />
                <span class="ml-1"> {{ formEditTag.title }} </span>
              </div>
              <hr class="text-muted" />
              <div class="px-3 pt-3">
                <b-button-group class="btn-groups">
                  <b-button
                    variant="light"
                    :class="[{ active: formEditTag.replyType.indexOf(1) > -1 }]"
                    @click="handleChangeReplyType(1, formEditTag.id)"
                  >
                    <img
                      src="@/assets/images/reply-text.png"
                      height="13"
                      width="13"
                      class="icon-type"
                    />
                  </b-button>
                  <b-button
                    variant="light"
                    :class="[{ active: formEditTag.replyType.indexOf(2) > -1 }]"
                    @click="handleChangeReplyType(2, formEditTag.id)"
                  >
                    <font-awesome-icon icon="image" class="icon-type" />
                  </b-button>
                  <b-button
                    variant="light"
                    :class="[{ active: formEditTag.replyType.indexOf(4) > -1 }]"
                    @click="handleChangeReplyType(4, formEditTag.id)"
                  >
                    <font-awesome-icon icon="link" class="icon-type" />
                  </b-button>
                </b-button-group>
                <p v-if="$v.formEditTag.replyType.$error" class="text-error">
                  กรุณาเลือกรูปแบบข้อความตอบกลับอย่างน้อย 1 รูปแบบ
                </p>
                <div class="mt-2" v-if="formEditTag.replyType.indexOf(1) > -1">
                  <InputTextArea
                    v-model="formEditTag.textReply"
                    textFloat="Text Reply"
                    placeholder="Text Reply"
                    isRequired
                    :id="`tagTextReplyEdit-1-${formEditTag.id}`"
                    rows="3"
                    :v="$v.formEditTag.textReply"
                  />
                </div>
                <div class="mt-2" v-if="formEditTag.replyType.indexOf(2) > -1">
                  <UploadFile
                    textFloat="Image Reply"
                    placeholder="Please Upload"
                    format="image"
                    :name="`profile_reply_${formEditTag.id}`"
                    :fileName="
                      formEditTag.imageReplyName || formEditTag.imageReply
                    "
                    isRequired
                    v-on:onFileChange="
                      val => onImageChange(val, formEditTag.id)
                    "
                    v-on:delete="onDeleteImage(formEditTag.id)"
                    deleteIcon
                    :v="$v.formEditTag.imageReply"
                    :text="
                      `*Please upload only file .png, .jpg size 1:1 and less than 10 MB`
                    "
                  />
                  <div class="w-25" v-if="formEditTag.imageReply">
                    <PreviewFile :urlPath="formEditTag.imageReply" />
                  </div>
                </div>
                <div class="mt-2" v-if="formEditTag.replyType.indexOf(4) > -1">
                  <InputText
                    v-model="formEditTag.linkReply"
                    textFloat="Link"
                    placeholder="Link"
                    isRequired
                    :id="`tagTextReplyEdit-4-${formEditTag.id}`"
                    type="text"
                    :v="$v.formEditTag.linkReply"
                  />
                </div>
              </div>
              <div
                class="px-3 pb-3 w-100 d-flex justify-content-end wrap-footer-reply"
              >
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="handleShowEditReply"
                  >ยกเลิก
                </b-button>
                <b-button
                  size="sm"
                  variant="main-primary"
                  class="ml-1"
                  @click="editReply"
                  >บันทึก
                </b-button>
              </div>
            </div>
            <ChatBoxNote
              v-if="isShowNote"
              :userGUID="otherInfo.externalId"
              :chatroomId="formatIdConversation"
              :handleLoadError="handleLoadError"
              @handle-show-note="val => (isShowNote = val)"
            />
            <div class="chat-box-mention" v-if="isShowMention">
              <div v-for="staff in staffList" :key="staff.id">
                <ChatBoxMention
                  :staff="staff"
                  @handleClickMention="handleClickMention"
                />
              </div>
            </div>

            <div class="topnav" v-if="enableMention">
              <a
                :class="[!isPrivateChat ? 'active' : '']"
                @click="handleClickPrivateChat(false)"
                >Chat</a
              >
              <a
                :class="[isPrivateChat ? 'active' : '']"
                @click="handleClickPrivateChat(true)"
                >Private Chat</a
              >
            </div>

            <div class="input-group d-flex">
              <div
                v-show="!isShowNote"
                id="chatInboxTextArea"
                name="chatInboxTextArea"
                :contenteditable="isEnableTextArea"
                @keypress="handlePushMessage"
                @keyup="resizeTextArea"
                @keydown="resizeTextArea"
                @focus="readMessage"
                @blur="blurMessage"
                @input="updateTheVariable"
                class="form-control"
                :class="[isPrivateChat ? 'type_msg_private' : 'type_msg']"
                data-text="Message"
              ></div>
            </div>
            <div class="border-top">
              <div
                v-if="profileNote.public.note && !isShowNote"
                class="public-note"
              >
                Public Note: <span>{{ profileNote.public.note }}</span>
              </div>
              <div class="px-3 py-2 d-flex justify-content-between">
                <!-- <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch1"
                  v-model="adminMode"
                  @change="handleChangeMode"
                />
                <label class="custom-control-label" for="customSwitch1">
                  {{ adminMode ? "Admin Mode" : "Bot Mode" }}</label
                >
              </div> -->
                <div class="d-flex justify-content-start">
                  <div class="d-flex align-items-center">
                    <!-- <b-button
                  class="send_btn btn-sm"
                  v-if="adminMode"
                  @click="
                    adminMode = false;
                    handleChangeMode();
                  "
                >
                  End Chat
                </b-button> -->
                    <div v-if="isVisibleLeaveBtn && enableMention" class="pr-2">
                      <b-button size="sm" @click="handleClickLeaveChat"
                        >Leave</b-button
                      >
                    </div>
                    <div v-else-if="adminMode" class="pr-2">
                      <b-dropdown
                        dropup
                        text="End Chat"
                        class="send_btn"
                        menu-class="py-0"
                        size="sm"
                      >
                        <b-dropdown-item
                          href="#"
                          @click="showModalConversationTopic(5)"
                          >End Chat</b-dropdown-item
                        >
                        <!-- <b-dropdown-item
                      href="#"
                      @click="$refs['modal-unresolve'].show()"
                      >End Chat with Ticket</b-dropdown-item
                    > -->
                        <b-dropdown-item
                          href="#"
                          @click="showModalConversationTopic(4)"
                          >Move to Pending</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <div class="pr-2 d-flex align-items-center">
                    <b-dropdown
                      dropup
                      text="Topics"
                      class="send_btn"
                      menu-class="py-0"
                      size="sm"
                      :disabled="disabledTopic"
                    >
                      <template>
                        <div v-if="topicsOption.length === 0" class="no-topics">
                          <span class="text-no-topics">Topics Not Found</span>
                        </div>
                        <div v-else>
                          <div v-for="(item, idx) in topicsOption" :key="idx">
                            <b-dropdown-item
                              v-if="item.id"
                              :key="idx"
                              @click="handleTopic(item.id, item)"
                              ><span class="text-topics">
                                {{ item.name }}</span
                              ></b-dropdown-item
                            >
                          </div>
                        </div>
                      </template>
                      <div class="search-topics-wrapper">
                        <InputText
                          id="input-search-topics"
                          v-model="textSearchTopics"
                          placeholder="Search Topics"
                          type="text"
                          icon="search"
                          @submit="onSearchTopics"
                          @onkeypress="handleEnterSearchTopics"
                        />
                      </div>
                    </b-dropdown>
                  </div>
                  <div class="pr-2 d-flex align-items-center">
                    <span
                      :class="[
                        'input-group-text attach_btn f-size-16',
                        { 'note-active': profileNote.public.note }
                      ]"
                      @click="isShowNote = !isShowNote"
                    >
                      <font-awesome-icon icon="clipboard-list" class="" />
                    </span>
                  </div>
                  <div class="pr-2 d-flex align-items-center">
                    <span
                      class="input-group-text attach_btn f-size-16"
                      @click="toggleSideBarSetTextReply"
                    >
                      <font-awesome-icon icon="comment-dots" class="" />
                    </span>
                  </div>
                  <!-- upload file -->
                  <div class="pr-2 d-flex align-items-center">
                    <span
                      class="input-group-text attach_btn"
                      @click="handleBrowseFile"
                    >
                      <font-awesome-icon icon="file-upload" class="" />
                    </span>
                    <input
                      type="file"
                      name="chatBoxInputFile"
                      id="chatBoxInputFile"
                      style="display: none"
                      accept=".txt, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, video/mp4, video/x-m4v, video/*,"
                      @change="preparePushFile"
                      multiple
                      ref="inputFile"
                    />
                  </div>
                  <!-- upload img -->
                  <div class="d-flex align-items-center">
                    <span
                      class="input-group-text attach_btn"
                      @click="handleBrowseImg"
                    >
                      <font-awesome-icon :icon="['fas', 'image']" class="" />
                    </span>
                    <input
                      type="file"
                      name="chatBoxInputImg"
                      id="chatBoxInputImg"
                      style="display: none"
                      accept="image/png, image/jpeg"
                      @change="preparePushFile"
                      multiple
                      ref="inputImg"
                    />
                  </div>
                  <b-button
                    class="send_btn btn-sm pr-0"
                    @click="preparePushMessage"
                  >
                    Send
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-sidebar
      v-if="false && !isShowBoth"
      id="sidebarRightChatOrder"
      right
      no-header
      class="h-100"
    >
      <!-- <div class="panel-close-profile-order pb-1 pt-2 px-3">
        <span @click="handleOpenProfileSideBar(false)" class="cursor-pointer">
          <font-awesome-icon :icon="['fas', 'times']" class="" />
          ปิด
        </span>
      </div> -->
      <ChatOrderPanel
        v-if="isShowProfileTab && isShowProfileOrder && !isShowBoth"
        :userChatInfo="userChatInfo"
        class="bg-white ChatOrderPanel"
        :socialType="socialType"
        :chatRoomId="formatIdConversation"
        :isShowBoth="isShowBoth"
      />
    </b-sidebar>

    <b-modal
      ref="modal-unresolve"
      title="กรอกหัวข้อ"
      @show="resetModalUnresolve"
    >
      <form>
        <b-form-group
          label-for="name-input-modal-unresolve"
          invalid-feedback="กรุณากรอกหัวข้อ"
          class="mb-0"
        >
          <b-form-textarea
            id="name-input-modal-unresolve"
            v-model="unresolveNoteSubject"
            placeholder="กรุณากรอก..."
            rows="3"
            max-rows="6"
            :state="stateUnresolveNoteSubject"
          ></b-form-textarea>
        </b-form-group>
        <div class="pt-2">
          <div>API Trigger</div>
          <select class="w-100 p-1 pb-1" v-model="apiTriggerIdSeleted">
            <option :value="0" disabled>กรุณาเลือก</option>
            <option
              v-for="(item, idx) in apiTriggerData"
              :key="idx"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <div
            class="text-danger text-error-api-trigger"
            v-if="!apiTriggerIdSeleted"
          >
            กรุณาเลือก API Trigger
          </div>
        </div>
      </form>

      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()"> ยกเลิก </b-button>
        <b-button @click="submitModalUnresolve()" class="btn-sumbit-sesolve">
          ตกลง
        </b-button>
      </template>
    </b-modal>
    <ChatBoxModalAssignTo
      ref="ChatBoxModalAssignTo"
      :chatRoomId="[formatIdConversation]"
      :conversationInfo="conversationInfo"
      :userChatInfo="userChatInfo"
      isMessageRef
      @selectedStaff="handleSelectedStaff"
      @addWaitingSend="addWaitingSend"
      @handleResponseMessageRef="handleResponseMessageRef"
    />
    <ChatBoxModalConversationTopic
      ref="ChatBoxModalConversationTopic"
      :chatRoomId="[formatIdConversation]"
      @selectedTopic="handleTopic"
      @endChat="btnEndChat"
      :selectedTopicId="selectedTopicId"
    />
    <ChatSideBarHistory
      ref="ChatSideBarHistory"
      :chatRoomId="formatIdConversation"
      :userId="userId"
      @reloadChat="prepareData"
      @selectSearchMsg="selectSearchMsg"
    />
    <ChatboxModalConfirmTextReply
      ref="ChatboxModalConfirmTextReply"
      @confirm="clickSelectTextReply"
    />
    <ChatBoxModalAlert ref="ChatBoxModalAlert" />
  </div>
</template>

<script>
import ChatBoxMention from "@/components/chat/chatBox/ChatBoxMention";
import ChatBoxMessageList from "@/components/chat/chatBox/ChatBoxMessageList.vue";
import ChatOrderPanel from "@/components/chat/chatOrder/ChatOrderPanel";
import ChatBoxNote from "@/components/chat/chatBox/ChatBoxNote";
import ChatBoxTranslateFlex from "@/components/chat/chatBox/ChatBoxTranslateFlex";
import ChatBoxModalAssignTo from "@/components/chat/chatBox/modal/ChatBoxModalAssignTo";
import ChatBoxModalConversationTopic from "@/components/chat/chatBox/modal/ChatBoxModalConversationTopic";
// import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import UploadFile from "@/components/inputs/UploadFile";
import PreviewFile from "@/components/inputs/PreviewFile";
import ChatSideBarHistory from "@/components/chat/ChatSideBarHistory";
import ChatboxModalConfirmTextReply from "@/components/chat/chatBox/modal/ChatboxModalConfirmTextReply";
import ChatBoxModalAlert from "@/components/chat/chatBox/modal/ChatBoxModalAlert";
import ChatDateTime from "@/components/chat/ChatDateTime";
import * as moment from "moment/moment";
import Vue from "vue";
import { SYSTEM_TYPES, SYSTEM_ROLES_NAME } from "@/helpers/constants.js";

import {
  required,
  numeric,
  minValue,
  requiredIf,
  url,
  minLength
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "ChatBox",
  components: {
    ChatBoxMessageList,
    ChatOrderPanel,
    ChatBoxNote,
    ChatBoxTranslateFlex,
    ChatBoxModalAssignTo,
    ChatBoxModalConversationTopic,
    InputText,
    InputTextArea,
    UploadFile,
    PreviewFile,
    ChatSideBarHistory,
    ChatboxModalConfirmTextReply,
    ChatBoxModalAlert,
    ChatDateTime,
    ChatBoxMention
    // InputSelect
  },
  props: {
    meProfile: {
      required: true,
      type: Object
    },
    // otherProfile: {
    //   required: true,
    //   type: Object
    // },
    isFromList: {
      required: false,
      type: Boolean
    },
    isShowBoth: {
      required: false,
      type: Boolean
    },
    formatIdConversation: {
      required: false,
      type: String
    },
    channelId: {
      required: false
      // type: Any
    },
    userId: {
      required: false,
      type: String
    },
    conversationInfo: {
      required: false,
      type: Object
    },
    userChatInfo: {
      required: false,
      type: Object
    },
    socialType: {
      required: false,
      type: Number
    },
    enableMention: {
      required: false,
      type: Boolean,
      default: false
    },
    enableEnterToSend: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPrivateChat: false,
      systemType: SYSTEM_TYPES,
      systemRolesName: SYSTEM_ROLES_NAME,
      isShownChatSideBarHistory: false,
      isShowProfileOrder: false,
      isNoConversation: false,
      isError: false,
      isPrepare: true,
      isFirstLoad: true,
      isLoadingConversation: false,
      isWaitingLoadingCon: false,
      coundDownWaitingLoadingCon: 5,
      textInput: "",
      // formatIdConversation: "",
      otherInfo: {
        targetApp: null,
        name: "",
        email: [],
        photoUrl: " ",
        role: "",
        phone: null,
        welcomeMessage: "",
        //new
        externalId: "",
        isTranslate: ""
      },
      filterConversation: {
        brandId: this.$route.query.brandID,
        limit: 20,
        search: ""
      },
      isDisplayPanelSearch: false,
      conversation: {
        data: []
      },
      isBusyCon: false,
      heightDivChatMsgCardBody: 0,
      dummyMessageList: [],
      adminMode:
        this.conversationInfo.mode === 3 ||
        this.conversationInfo.mode === 4 ||
        this.conversationInfo.topicId
          ? true
          : false,
      endChatMode: 1,
      choiceReply: [],
      showSetReply: false,
      formEditTag: {
        id: "",
        type: 1,
        title: "",
        shortkeys: "",
        textReply: "",
        replyType: [],
        imageReply: "",
        imageReplyName: "",
        linkReply: ""
      },
      isShowNote: false,
      isShowTranslate: false,
      isShowMention: false,
      textTranslated: "",
      isLoadingTranslate: false,
      timeCallTranslate: null,
      isShowProfileTab: true,
      unresolveNoteSubject: "",
      apiTriggerIdSeleted: 0,
      apiTriggerData: [],
      typeList: [
        { id: 1, name: "Public" },
        { id: 2, name: "Private" }
      ],
      selectedTopicId: 0,
      disabledTopic: false,
      disabledAssignTo: false,
      timer: null,
      totalMarkText: 0,
      selectedNumberMarkText: 0,
      isLoadingSearch: false,
      isDisplayMark: false,
      refMarkList: [],
      brandId: this.$route.query.brandID,
      staffList: [],
      adminMention: [],
      tooltipText: "",
      isEnableTextArea: true,
      textSearchTopics: "",
      topicsOption: []
    };
  },
  validations() {
    return {
      formEditTag: {
        type: {
          required,
          numeric,
          minValue: minValue(1)
        },
        title: { required },
        shortkeys: { required },
        replyType: {
          required,
          minLength: minLength(1)
        },
        textReply: {
          required: requiredIf(function() {
            return this.formEditTag.replyType.indexOf(1) > -1;
          })
        },
        imageReply: {
          required: requiredIf(function() {
            return this.formEditTag.replyType.indexOf(2) > -1;
          })
        },
        imageReplyName: {
          required: requiredIf(function() {
            return (
              this.formEditTag.replyType.indexOf(2) > -1 &&
              !this.formEditTag.imageReply
            );
          })
        },
        linkReply: {
          url,
          required: requiredIf(function() {
            return this.formEditTag.replyType.indexOf(4) > -1;
          })
        }
      }
    };
  },
  methods: {
    prepareData: async function() {
      this.isFirstLoad = true;
      this.isPrepare = true;
      this.isError = false;
      this.$emit("set-show-title", false);
      // this.formatIdConversation = `${this.meProfile.chatId}___${this.otherProfile.chatId}`;
      // if (this.isFromList) {
      this.getOther();
      // } else {
      //   this.updateOther();
      // }
      await this.getConversation(true, false);
      // this.readMessage();
    },
    getStaff: async function(search) {
      const textSearch = search || "";
      const response = await this.$axios.post(
        `${this.$chatBaseUrl}/user/staff/${this.brandId}`,
        {
          search: textSearch,
          brandId: this.brandId
        }
      );

      if (response.data.result === 1) {
        const { staffList } = response.data.detail;
        if (staffList.length > 0) {
          this.staffList = staffList.filter(staff => {
            return (
              !this.adminMention.some(mention => mention.id === staff.id) &&
              staff.id !== this.chatAddminProfileId
            );
          });
        } else {
          this.staffList = staffList;
        }
      }
    },
    onSearchTopics: function() {
      if (this.textSearchTopics === "" || !this.textSearchTopics) {
        this.topicsOption = this.topicList;
      } else {
        this.topicsOption = this.topicList.filter(topic => {
          return topic.name
            .toLowerCase()
            .includes(this.textSearchTopics.toLowerCase());
        });
      }
    },
    handleEnterSearchTopics: function(val) {
      if (val && val.keyCode === 13) {
        this.onSearchTopics();
      }
    },
    handleClickLeaveChat: async function() {
      const payload = {
        userGUID: this.userChatInfo.externalId,
        channelId: this.channelId
      };
      const response = await fetch(
        `${this.$chatBaseUrl}/chatroom/leave-chat/mention-group`,
        {
          method: "POST",
          headers: new Headers(this.$headersChat),
          body: JSON.stringify(payload)
        }
      );
      if (response.status === 200) {
        this.$emit("show-list-chat");
        this.$emit("reload-detail-list", this.formatIdConversation);
      }
    },
    handleClickPrivateChat(val) {
      if (!this.isVisibleLeaveBtn) {
        this.isPrivateChat = val;
      }
    },
    formatFullName(firstname, lastname) {
      return firstname + " " + lastname;
    },
    updateMentionsOnDelete() {
      this.adminMention = this.adminMention.filter(user => {
        const mentionName = this.formatMentionName(user);
        return this.textInput.includes(mentionName); // เก็บ mention ที่ยังอยู่
      });
    },

    formatMentionName(user) {
      return `@${this.formatFullName(user.firstname, user.lastname)}`;
    },
    formatTextInputWithMentions() {
      const textarea = document.getElementById("chatInboxTextArea");
      let formattedText = this.textInput;

      this.adminMention.forEach(user => {
        const mentionName = this.formatMentionName(user);
        const spanText = `<span style="color: blue;">${mentionName}</span>`;
        const regex = new RegExp(
          `@${this.formatFullName(user.firstname, user.lastname)}`,
          "g"
        );

        // แทนที่ข้อความ @ชื่อ ด้วย <span>
        formattedText = formattedText.replace(regex, spanText);
      });

      textarea.innerHTML = formattedText;
      this.moveCursorToEnd(textarea);
    },
    handleClickMention(val) {
      // สร้างข้อความที่เป็นชื่อเต็มของ admin
      const mentionText = `@${this.formatFullName(
        val.firstname,
        val.lastname
      )}`;

      // หาตำแหน่งของ @ ที่อยู่ในข้อความ
      const atIndex = this.textInput.lastIndexOf("@");

      // หากพบ @ ในข้อความ ให้แทนที่ @ ด้วยชื่อเต็ม
      if (atIndex !== -1) {
        // แทนที่ @ ที่เจอด้วยชื่อเต็ม
        this.textInput =
          this.textInput.slice(0, atIndex) +
          mentionText +
          this.textInput.slice(atIndex + mentionText.length);
      } else {
        // หากไม่มี @ ให้เพิ่มชื่อเข้าไปที่ตำแหน่งปัจจุบัน
        this.textInput += mentionText;
      }

      // เพิ่ม admin ที่เลือกเข้าไปใน adminMention หากยังไม่มีอยู่
      if (!this.adminMention.some(user => user.id === val.id)) {
        this.adminMention.push(val);
      }

      // ซ่อนกล่องแสดงชื่อ
      this.isShowMention = false;

      // เรียกฟังก์ชันที่จะจัดการการแสดงผล
      this.formatTextInputWithMentions();
    },

    updateTheVariable(event) {
      const { data, inputType } = event;

      const textArea = document.getElementById("chatInboxTextArea");

      const innerText = textArea.innerText;

      this.textInput = innerText;
      if (
        inputType === "deleteContentBackward" &&
        this.adminMention.length > 0
      ) {
        this.updateMentionsOnDelete();
      }

      if (data) {
        if (
          data === "@" &&
          this.isPrivateChat &&
          this.conversationInfo.lastAdminId ==
            this.$store.state.chatAdminProfile.externalId
        ) {
          this.isShowMention = true;
          this.getStaff();
          if (textArea) {
            textArea.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      } else {
        // ลบข้อความทั้งหมด
        this.isShowMention = false;
        if (this.textInput === "\n") {
          textArea.innerHTML = "";
          this.adminMention = [];
        }
      }
    },
    moveCursorToEnd(element) {
      const range = document.createRange();
      const sel = window.getSelection();

      range.selectNodeContents(element);
      range.collapse(false); // เคอร์เซอร์อยู่ที่ท้ายข้อความ
      sel.removeAllRanges();
      sel.addRange(range);
      element.focus();
    },
    getOther() {
      this.$emit("set-selected-user-chat", {
        externalId: ""
      });
      fetch(`${this.$chatBaseUrl}/user/${this.userId}`, {
        method: "GET",
        headers: new Headers(this.$headersChat)
      })
        .then(response => response.json())
        .then(res => {
          if (res.result) {
            this.otherInfo = res.detail;
            this.$emit("set-selected-user-chat", res.detail);
            this.$store.commit(
              "setDispalyNameCurrentChat",
              res.detail.displayname
            );
            this.$store.commit(
              "UPDATE_CHAT_ISTRANSLATE",
              this.otherInfo.isTranslate
            );
          } else {
            this.handleLoadError();
          }
        })
        .catch(error => {
          this.handleLoadError();
          console.log("error", error);
        });
    },
    async setDisplaySearch(val) {
      this.isDisplayMark = false;
      if (val == false) {
        this.totalMarkText = 0;
        this.selectedNumberMarkText = 0;
        this.filterConversation.search = "";
        this.refMarkList = [];
      }
      this.isDisplayPanelSearch = val;
      setTimeout(() => {
        let objDiv = document.getElementById("chatMsgCardBody");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    },
    submitSearchChatHistory() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.getConversation(true, false, true);
    },
    handleKeyupSearchChatHistory() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getConversation(true, false, true);
      }, 2000);
    },
    handleReplaceLink(items) {
      let list = items.map(item => {
        if (item.messageType == 1 && item.message) {
          let message = item.message.replace(
            /(https?:\/\/[^\s<]+(<mark[^>]*>[^<]*<\/mark>[^<]*)*)/g,
            match => {
              let cleanUrl = match.replace(/<[^>]*>/g, "");
              return `<a href="${cleanUrl}" target="_blank">${match}</a>`;
            }
          );

          message = message.replace(
            /@\{([^}]+)\}/g,
            `<span style="color: blue;">@$1</span>`
          );

          item.message = message;
        }
        return {
          ...item
        };
      });
      return list;
    },
    handleMarkText(items, search) {
      this.totalMarkText = 0;
      this.refMarkList = [];
      let list = items.map(item => {
        let messageHtml = "";
        if (item.userType != "system" && search) {
          var pattern = new RegExp("(" + search + ")(?![^<]*>)", "gi");
          item.message = item.message.replace(pattern, x => {
            this.refMarkList.push(item.id);
            return `<mark id="ref-${item.id}">${x}</mark>`;
          });
          messageHtml = item.message;
        } else messageHtml = item.message;
        if (item.messageType == 1) item.message = messageHtml;
        return {
          ...item,
          messageHtml: messageHtml
        };
      });
      this.totalMarkText = this.refMarkList.length;
      this.selectedNumberMarkText = this.totalMarkText ? this.totalMarkText : 0;
      return list;
    },
    handleFocusTextMark(type) {
      document
        .getElementById(
          `ref-${
            this.refMarkList[this.totalMarkText - this.selectedNumberMarkText]
          }`
        )
        .classList.remove("active");
      if (type == "up") {
        this.selectedNumberMarkText -= 1;
      } else if (type == "down") {
        this.selectedNumberMarkText += 1;
      }
      let ref = this.totalMarkText - this.selectedNumberMarkText;
      let ele = document.getElementById(`ref-${this.refMarkList[ref]}`);
      ele.classList.add("active");
      ele.scrollIntoView();
    },
    async selectSearchMsg(msg, total, index, filter) {
      let duration = this.$moment.duration(
        this.$moment().diff(this.$moment(msg.sendTime))
      );
      let durationDays = duration.asDays();
      if (msg.messageType != 1) {
        this.$refs.ChatBoxModalAlert.show("ประเภทแชทไม่ถูกต้อง");
      } else if (msg.isImport) {
        this.$refs.ChatBoxModalAlert.show(
          "ไม่พบรายการแชทเนื่องจากเป็นแชทที่นำเข้าข้อมูล"
        );
      } else if (durationDays > 90) {
        this.$refs.ChatBoxModalAlert.show(
          "ไม่พบรายการแชทเนื่องจากมีอายุเกิน 90 วัน"
        );
      } else {
        this.filterConversation.search = filter.search;
        let ref = msg.messageId;
        await this.getConversation(true, false, true, ref);
        this.isDisplayPanelSearch = true;
        this.selectedNumberMarkText =
          this.refMarkList.length - this.refMarkList.indexOf(msg.messageId);
      }
    },
    async getConversation(
      isForceScrollToBottom,
      isInfiniteScroll,
      isSearch = false,
      searchHighlightId = null
    ) {
      if (this.isWaitingLoadingCon && this.coundDownWaitingLoadingCon > 0) {
        this.coundDownWaitingLoadingCon--;
        return;
      }
      this.isWaitingLoadingCon = true;
      this.coundDownWaitingLoadingCon = 5;
      let startingAfter = "";
      if (!isSearch && isInfiniteScroll && this.conversation.data.length) {
        startingAfter = this.conversation.data[
          this.conversation.data.length - 1
        ].id;
      }
      if (isSearch) {
        this.isLoadingSearch = true;
        this.conversation.data = [];
        this.isFirstLoad = true;
        this.isError = false;
        this.isBusyCon = true;
        this.isDisplayMark = true;
      }
      await fetch(
        `${this.$chatBaseUrl}/message/chatroom/${this.formatIdConversation}?start=${startingAfter}&length=${this.filterConversation.limit}&search=${this.filterConversation.search}`,
        {
          method: "GET",
          headers: new Headers(this.$headersChat)
          // body: JSON.stringify(body)
        }
      )
        .then(response => {
          // if (response.status == 401) {
          //   this.$cookies.remove("master-chatagent_session_token");
          //   this.$cookies.remove("master-chatagent_username");
          //   this.$router.push({ path: "/login" });
          // }
          return response.json();
        })
        .then(async result => {
          this.isWaitingLoadingCon = false;
          let msgList = [];
          if (this.filterConversation.search) {
            msgList = await this.handleMarkText(
              result.detail,
              this.filterConversation.search
            );
            if (this.totalMarkText == 0) {
              this.$refs.ChatBoxModalAlert.show("ไม่พบรายการแชท");
            }
          } else {
            msgList = [...result.detail];
          }
          if (result.result) {
            // let length = result.detail.length;
            msgList = await this.handleReplaceLink(msgList);
            // this.adminMode = msgList;
            // if (result.length > 0) {
            //   result.result = result.result.reverse();
            // }
            this.heightDivChatMsgCardBody = 0;
            if (msgList == null || (msgList.length < 1 && this.isFirstLoad)) {
              this.isFirstLoad = false;
              this.isPrepare = false;
              this.isNoConversation = true;
              return;
            }
            this.isNoConversation = false;
            if (isInfiniteScroll) {
              this.isLoadingConversation = false;
              let tempObjDiv = document.getElementById("chatMsgCardBody");
              this.heightDivChatMsgCardBody = tempObjDiv.scrollHeight;
            }
            msgList.forEach(mess => {
              if (this.dummyMessageList.length) {
                let indx = this.dummyMessageList.findIndex(
                  temp => mess.offlineId && mess.offlineId === temp.offlineId
                );
                if (indx >= 0) {
                  this.dummyMessageList.splice(indx, 1);
                }
              }
            });
            if (this.conversation.data.length && msgList && !isInfiniteScroll) {
              let tempResultData = msgList;
              let tempConData = this.conversation.data;

              let idxCon = tempConData.findIndex(
                itm => itm.id === tempResultData[tempResultData.length - 1].id
              );
              tempConData.splice(0, idxCon + 1);
              this.conversation.data = tempResultData.concat(tempConData);
            } else {
              this.conversation.data = this.conversation.data.concat(msgList);
            }
            if (isForceScrollToBottom && !this.isError) {
              setTimeout(() => {
                let objDiv = document.getElementById("chatMsgCardBody");
                objDiv.scrollTop = objDiv.scrollHeight;
              }, 100);
            }
            if (!this.isFirstLoad) {
              let objDiv2 = document.getElementById("chatMsgCardBody");
              setTimeout(() => {
                if (isInfiniteScroll) {
                  objDiv2.scrollTop =
                    objDiv2.scrollHeight - this.heightDivChatMsgCardBody;
                } else {
                  if (
                    objDiv2.scrollHeight -
                      objDiv2.scrollTop -
                      objDiv2.clientHeight <
                    150
                  ) {
                    objDiv2.scrollTop = objDiv2.scrollHeight;
                  }
                }
              }, 100);
            }
            if (this.isFirstLoad) {
              setTimeout(() => {
                document.getElementById("chatMsgCardBody").addEventListener(
                  "scroll",
                  async () => {
                    if (
                      document.getElementById("chatMsgCardBody").scrollTop ==
                        0 &&
                      !this.isBusyCon
                    ) {
                      this.isBusyCon = true;
                      this.isLoadingConversation = true;
                      this.heightDivChatMsgCardBody = 0;
                      this.getConversation(false, true);
                    }
                  },
                  false
                );
              }, 500);
              this.isPrepare = false;
              this.isFirstLoad = false;
            }
            // if (length) {
            //   let date = msgList[length - 1].createdTime;
            //   let duration = moment.duration(moment().diff(date));
            //   let days = duration.asDays();
            //   if (
            //     !this.isShownChatSideBarHistory &&
            //     this.conversation.data.length > 20 &&
            //     days >= 90
            //   ) {
            //     this.$refs.ChatSideBarHistory.show();
            //     this.isShownChatSideBarHistory = true;
            //   }
            // }
            this.$emit("handle-no-list-chat", false);
            if (this.totalMarkText > 0) {
              setTimeout(() => {
                if (!searchHighlightId) {
                  searchHighlightId = this.refMarkList[0];
                }
                let ele = document.getElementById(`ref-${searchHighlightId}`);
                ele.classList.add("active");
                ele.scrollIntoView();
              }, 500);
            }
          } else {
            this.handleLoadError();
          }
          if (isInfiniteScroll) {
            if (msgList.length > 0) {
              this.isBusyCon = false;
            } else {
              this.isBusyCon = true;
            }
          }
        })
        .catch(error => {
          console.log("error", error);
          this.handleLoadError();
        });
      this.isLoadingSearch = false;
    },
    handlePushMessage(e) {
      const isEnterKey = e.keyCode === 13;
      const isShiftPressed = e.shiftKey;
      const isValidInput = this.textInput.trim() !== "/";

      if (!this.enableEnterToSend && isEnterKey) {
        e.preventDefault();
      } else if (
        this.enableEnterToSend &&
        isEnterKey &&
        !isShiftPressed &&
        isValidInput
      ) {
        e.preventDefault();
        this.preparePushMessage();
      }
    },
    preparePushMention(text) {
      if (typeof text !== "string") {
        console.warn("Text is not a string:", text);
        return text;
      }

      this.adminMention.forEach(item => {
        const mentionWithBraces = `@{${this.formatFullName(
          item.firstname,
          item.lastname
        ).trim()}}`;

        const regex = new RegExp(
          `@${item.firstname}\\s*${item.lastname}`,
          "gi"
        );

        text = text.replace(regex, mentionWithBraces);
      });

      return text;
    },
    preparePushMessage() {
      if (this.textInput.trim().length > 0 && !this.isLoadingTranslate) {
        if (this.adminMention && this.adminMention.length > 0) {
          this.textInput = this.preparePushMention(this.textInput);
        }
        let dataSend = {
          text: this.textInput,
          imageUrl: "",
          attachments: ""
        };
        if (this.isShowTranslate) {
          dataSend.text = `${dataSend.text}\n---------------\n${this.textTranslated}`;
          this.isShowTranslate = false;
          this.textTranslated = "";
        }

        this.textInput = "";
        var textArea = document.getElementById("chatInboxTextArea");
        textArea.innerHTML = "";
        this.sendMessage(dataSend, 1); // 1 = type text
      }
    },
    async preparePushFile(e) {
      if (e.target.files.length) {
        let isImage = true;
        await e.target.files.forEach(async file => {
          let dataSend = {
            text: "",
            imageUrl: null,
            attachments: null
          };
          console.log(file.name);
          if (file.size < 20971520) {
            //Max 20MB
            let readerFile = new FileReader();
            await readerFile.readAsDataURL(file);
            readerFile.onload = async () => {
              // console.log("file ----->", file);
              dataSend.text = await this.getFileName(file.name);
              let fileName = await this.getExtension(file.name).toLowerCase();
              dataSend.attachments = readerFile.result;
              // console.log("readerFile ----->", readerFile);
              // console.log("dataSend ----->", dataSend);
              if (
                fileName == "jpg" ||
                fileName == "png" ||
                fileName == "gif" ||
                fileName == "jpeg"
              ) {
                isImage = true;
                await this.sendMessage(dataSend, 4);
              } else {
                isImage = false;
                await this.sendMessage(dataSend, 8);
              }
            };
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              "ไฟล์นี้มีขนาดใหญ่เกินไป (Max 20MB)"
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        });
        if (isImage) {
          this.$refs.inputImg.value = null;
        } else {
          this.$refs.inputFile.value = null;
        }
      }
    },
    getFileName(fullPath) {
      var name = fullPath.split("\\");
      return name[name.length - 1];
    },
    getExtension(filename) {
      var parts = filename.split(".");
      return parts[parts.length - 1];
    },
    async assignToStaff(externalId) {
      this.$store.dispatch("setIsLoading", true);

      let messageRef = await this.addWaitingSendUpdataStaff(
        this.$store.state.chatAdminProfile
      );

      let body = {
        chatRoomId: [this.formatIdConversation],
        externalId: externalId,
        brandID: this.$route.query.brandID,
        messageRef: messageRef
      };
      let response = await this.$axios.post(
        `${this.$chatBaseUrl}/user/staff/update`,
        body
      );

      if (response.data.result) {
        await this.handleResponseMessageRef(
          messageRef,
          response.data,
          "update-staff"
        );
        this.$store.dispatch("setDisplayCheckboxSelectUserChat", false);
        this.$store.dispatch("setIsLoading", false);
      } else {
        await this.handleResponseMessageRef(
          messageRef,
          response.data,
          "update-staff"
        );
        this.$store.commit("setMessageGlobalAlertError", response.data.detail);
        this.$store.commit("setShowGlobalAlertError", true);
        this.$store.dispatch("setIsLoading", false);
      }
    },
    async addWaitingSendUpdataStaff(item) {
      let messageRef = `${this.userChatInfo.id}-${moment().valueOf()}`;
      let dataSend = {
        text: `Assign to `,
        adminName: `${this.$store.state.chatAdminProfile.firstname} ${this.$store.state.chatAdminProfile.lastname}`,
        toAdminName: `${item.firstname} ${item.lastname}`,
        systemType: this.systemType.AssignTo,
        userType: "system"
      };
      await this.addWaitingSend(dataSend, 1, messageRef);

      return messageRef;
    },
    async addWaitingSend(dataSend, type, messageRef) {
      let createdTime = moment().format("YYYY-MM-DDTHH:mm:ss");
      let msg = {
        id: messageRef,
        mode: 3,
        topicId: dataSend.topicId || null,
        topicName: dataSend.topicName || null,
        chatroomId: this.userChatInfo.id,
        message: dataSend.text || "",
        messageType: type,
        readBy: null,
        custom: {},
        adminRead: 1,
        createdBy: this.chatAddminProfileId,
        createdTime: createdTime,
        userType: dataSend.userType || "admin",
        filePath: dataSend.attachments || null,
        logoUrl: null,
        adminName: dataSend.adminName || null,
        toAdminName: dataSend.toAdminName || null,
        toAdminExternalId: null,
        isFirstMessage: false,
        roomDetail: null,
        isWaitSend: true,
        isSendFail: false,
        messageRef: messageRef,
        systemType: dataSend.systemType || 0
      };
      this.conversation.data = await [msg, ...this.conversation.data];
      setTimeout(() => {
        let objDiv = document.getElementById("chatMsgCardBody");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    },
    async sendMessage(dataSend, type) {
      let lastConversation = this.conversation.data[0];

      if (!this.$store.state.chatAdminProfile.isAvailable)
        await this.$store.dispatch("updateChatAdminProfileIsAvailable", true);

      let messageRef = `${this.userChatInfo.id}-${moment().valueOf()}`;
      // if (this.$store.state.chatAdminProfile.userType == this.systemRolesName.SUPERADMIN) {
      if (
        !this.isPrivateChat &&
        ((lastConversation.userType == "admin" &&
          lastConversation.createdBy &&
          this.$store.state.chatAdminProfile.id !=
            lastConversation.createdBy) ||
          (lastConversation.userType == "system" &&
            lastConversation.toAdminExternalId &&
            this.$store.state.chatAdminProfile.externalId !=
              lastConversation.toAdminExternalId) ||
          (lastConversation.userType == "user" &&
            (lastConversation.roomDetail?.lastAdminId == "" ||
              lastConversation.roomDetail?.lastAdminId !=
                this.$store.state.chatAdminProfile.externalId)))
      ) {
        await this.assignToStaff(this.$store.state.chatAdminProfile.externalId);
      }
      dataSend.userType = "admin";
      dataSend.systemType = this.systemType.Message;
      await this.addWaitingSend(dataSend, type, messageRef);
      // }
      const adminMentionListId =
        this.adminMention.length > 0
          ? this.adminMention.map(item => item.id)
          : [];
      this.adminMention = [];
      let body = {
        messages: [
          {
            mode: 1,
            timeStamp: "",
            replyToken: null,
            prechatInfo: {
              SurveyScore: "1.0"
            },
            message: {
              messageType: type,
              messageId: "",
              text: dataSend.text,
              base64: dataSend.attachments,
              custom: {},
              imageUrl: dataSend.imageUrl,
              messageRef: messageRef,
              isReply: dataSend.isReply ? true : false
            },
            source: {
              userGUID: this.otherInfo.externalId,
              channelId: this.channelId,
              isPrivateChat: this.isPrivateChat,
              adminMention: adminMentionListId
            }
          }
        ]
      };
      if (!type) {
        return;
      }
      await fetch(`${this.$chatBaseUrl}/message`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          if (result.result) {
            this.adminMode = true;
            // this.getConversation(true, true);
            await this.handleResponseMessageRef(messageRef, result, "message");
          } else {
            await this.handleResponseMessageRef(messageRef, result, "message");
            this.handleLoadError();
          }
        })
        .catch(error => {
          this.handleLoadError();
          console.log("error", error);
        });

      this.choiceReply = [];
      this.textInput = "";
    },
    async handleResponseMessageRef(
      messageRef = "",
      resResult,
      type = "message"
    ) {
      if (!messageRef) return;

      let messageRefIndx = this.conversation.data.findIndex(
        item => messageRef === item.messageRef
      );
      if (resResult.result) {
        if (messageRefIndx >= 0) {
          let conversationData = [...this.conversation.data];
          let messageDetail = null;
          if (type == "message") {
            let filtered = await resResult.detail.filter(
              ele => ele.messageDetail.messageRef == messageRef
            );
            if (filtered.length > 0) {
              messageDetail = filtered[0].messageDetail;
            }
          } else if (type == "change-mode" || type == "update-staff") {
            let filtered = await resResult.detail.filter(
              ele => ele.messageRef == messageRef
            );
            if (filtered.length > 0) {
              messageDetail = filtered[0];
              if (type == "update-staff") {
                this.conversationInfo.lastAdminId =
                  messageDetail.toAdminExternalId;
              }
            }
          } else {
            messageDetail = resResult.detail;
          }
          if (messageDetail) {
            conversationData[messageRefIndx] = messageDetail;
            conversationData[messageRefIndx].isWaitSend = false;
            conversationData[messageRefIndx].isSendFail = false;
          } else {
            conversationData[messageRefIndx].isWaitSend = false;
            conversationData[messageRefIndx].isSendFail = true;
          }
          this.conversation.data = [...conversationData];
        }
      } else {
        if (messageRefIndx >= 0) {
          let conversationData = [...this.conversation.data];
          conversationData[messageRefIndx].isWaitSend = false;
          conversationData[messageRefIndx].isSendFail = true;
          this.conversation.data = [...conversationData];
        }
      }
    },
    handleBrowseImg() {
      this.$refs.inputImg.click();
      this.readMessage();
    },
    handleBrowseFile() {
      this.$refs.inputFile.click();
      this.readMessage();
    },
    readMessage() {
      this.choiceReply = [];
      if (this.textInput[0] === "/") {
        let keyword = {
          userGUID: this.$store.state.chatAdminProfile.externalId,
          Shortkeys: this.textInput.substring(1),
          brandID: this.$route.query.brandID
        };
        this.getTextReply(keyword);
      }
      fetch(`${this.$chatBaseUrl}/message/read/${this.formatIdConversation}`, {
        method: "GET",
        headers: new Headers(this.$headersChat)
      })
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.$emit("set-read-conversation", this.formatIdConversation);
          } else {
            this.handleLoadError();
          }
        })
        .catch(error => {
          this.handleLoadError();
          console.log("error", error);
        });
    },
    blurMessage() {
      setTimeout(() => {
        this.choiceReply = [];
      }, 250);
      if (this.showSetReply) {
        this.showSetReply = false;
      }
    },
    resetModalUnresolve() {
      this.unresolveNoteSubject = "";
      this.apiTriggerIdSeleted = 0;
    },
    submitModalUnresolve() {
      if (!this.unresolveNoteSubject || !this.apiTriggerIdSeleted) {
        return;
      } else {
        this.adminMode = false;
        this.endChatMode = 2;
        this.$refs["modal-unresolve"].hide();
        this.handleChangeModeResolveAndUnresolve();
      }
    },
    showModalConversationTopic(mode) {
      if (mode === 5 && !this.selectedTopicId) {
        this.$refs["ChatBoxModalConversationTopic"].show(mode);
      } else {
        this.btnEndChat(mode);
      }
    },
    btnEndChat(mode) {
      if (mode == 5) this.selectedTopicId = 0;
      this.adminMode = false;
      this.endChatMode = mode;
      // this.resetModalUnresolve();
      this.handleChangeModeResolveAndUnresolve();
    },
    handleChangeModeResolveAndUnresolve() {
      // this.$store.dispatch("setIsLoading", true);
      // let body = {
      //   userGUID: this.userChatInfo.externalId,
      //   subject: this.unresolveNoteSubject || "",
      //   channelId: this.channelId,
      //   apiTriggerId: this.apiTriggerIdSeleted
      // };
      // fetch(`${this.$chatBaseUrl}/chatroom/endchat/createTicket`, {
      //   method: "POST",
      //   headers: new Headers(this.$headersChat),
      //   body: JSON.stringify(body)
      // })
      //   .then(response => response.json())
      //   .then(result => {
      //     this.$store.dispatch("setIsLoading", false);
      //     if (result.result) {
      this.apiTriggerIdSeleted = 0;
      this.handleChangeMode();
      //   } else {
      //     this.handleLoadError();
      //   }
      // })
      // .catch(error => {
      //   this.$store.dispatch("setIsLoading", false);
      //   this.handleLoadError();
      //   console.log("error", error);
      // });
    },
    async handleChangeMode() {
      let messageRef = `${this.userChatInfo.id}-${moment().valueOf()}`;

      let body = {
        mode: this.endChatMode,
        chatRoomId: [this.formatIdConversation],
        externalId: this.$store.state.chatAdminProfile.externalId,
        brandID: this.$route.query.brandID,
        messageRef: messageRef
      };
      this.$store.dispatch("setIsLoading", true);
      let dataSend = {
        text: `Move to ${
          this.endChatMode == 5
            ? "Closed"
            : this.endChatMode == 4
            ? "Pending"
            : "Case"
        }`,
        adminName: `${this.$store.state.chatAdminProfile.firstname} ${this.$store.state.chatAdminProfile.lastname}`,
        systemType:
          this.endChatMode == 5
            ? this.systemType.MoveToClose
            : this.endChatMode == 4
            ? this.systemType.MoveToPending
            : this.systemType.MoveToCase,
        userType: "system"
      };
      await this.addWaitingSend(dataSend, 1, messageRef);

      await fetch(`${this.$chatBaseUrl}/chatroom/bulk/change-mode`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          if (result.result) {
            this.adminMode =
              body.mode === 3 || body.mode === 4 || body.topicId ? true : false;
            // this.getConversation(true, false);
            // this.$emit("reload-detail-list", this.formatIdConversation);

            await this.handleResponseMessageRef(
              messageRef,
              result,
              "change-mode"
            );
          } else {
            await this.handleResponseMessageRef(
              messageRef,
              result,
              "change-mode"
            );
            this.handleLoadError();
          }
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(error => {
          this.$store.dispatch("setIsLoading", false);
          this.handleLoadError();
          console.log("error", error);
        });
    },
    getApiTrigger() {
      fetch(
        `${this.$chatBaseUrl}/chatroom/apitrigger/all/${this.$route.query.brandID}`,
        {
          method: "GET",
          headers: new Headers(this.$headersChat)
        }
      )
        .then(response => response.json())
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          if (result.result) {
            this.apiTriggerData = result.detail?.data;
          }
        })
        .catch(error => {
          this.$store.dispatch("setIsLoading", false);
          this.handleLoadError();
          console.log("error", error);
        });
    },
    handleKeydown(event) {
      const textarea = document.getElementById("chatInboxTextArea");
      const sel = window.getSelection();
      const range = sel.getRangeAt(0);

      if (event.key === "Backspace" || event.key === "Delete") {
        const currentNode = range.startContainer;
        const parentNode = currentNode.parentNode;

        // ตรวจสอบว่ากำลังลบอยู่ใน `<span>` หรือไม่
        if (
          parentNode.tagName === "SPAN" &&
          parentNode.style.color === "blue"
        ) {
          event.preventDefault(); // ป้องกันการลบปกติ

          // ลบ `<span>` ทั้งหมด
          parentNode.remove();

          // อัปเดตข้อความใน `textInput`
          this.textInput = textarea.innerText.trim();

          return;
        }
      }
    },

    resizeTextArea(e) {
      // var textArea = document.getElementById("chatInboxTextArea");
      var textArea = document.getElementById("chatInboxTextArea");
      const { translationEnable } = this.selectedBrand;
      this.handleKeydown(e);
      if (
        e.keyCode === 13 &&
        this.textInput &&
        this.textInput[0] === "/" &&
        this.choiceReply.length
      ) {
        e.preventDefault();
        this.textInput = this.choiceReply[0].textReply;
        this.choiceReply = [];

        if (this.$store.state.isChatTranslate && translationEnable) {
          this.isShowTranslate = true;
          this.checkTranslate();
        }
      } else {
        if (!(e.keyCode === 13 && !e.shiftKey)) {
          if (this.textInput[0] === "/") {
            let keyword = {
              userGUID: this.$store.state.chatAdminProfile.externalId,
              Shortkeys: this.textInput.substring(1),
              brandID: this.$route.query.brandID
            };
            this.getTextReply(keyword);
          } else if (
            this.textInput.trim() != "" &&
            this.textInput[0] !== "/" &&
            this.$store.state.isChatTranslate &&
            translationEnable
          ) {
            this.isShowTranslate = true;
            this.checkTranslate();
          } else {
            this.isShowTranslate = false;
          }
        }
        this.choiceReply = [];

        textArea.style.height = "auto";
        textArea.style.height = textArea.scrollHeight + "px";
      }

      setTimeout(() => {
        let objDiv = document.getElementById("chatMsgCardBody");
        objDiv.scrollTop = objDiv.scrollHeight;
      }, 100);
    },
    getTextReply(keyword) {
      this.choiceReply = [];
      if (!keyword) {
        keyword = {
          userGUID: this.$store.state.chatAdminProfile.externalId,
          Shortkeys: "",
          brandID: this.$route.query.brandID
        };
      }
      this.$axios
        .post(`${this.$chatBaseUrl}/reply/Find`, keyword)
        .then(result => {
          if (result.data.result) {
            if (result.data.detail.length > 0) {
              this.choiceReply = result.data.detail;
            }
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              result.data.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    async delReply(id, index) {
      this.$store.commit("SPLICE_TEXT_REPLY_LIST", { idx: index });

      await this.$axios
        .delete(`${this.$chatBaseUrl}/reply/${id}`)
        .then(result => {
          if (!result.data.result == 1) {
            this.$store.dispatch("getChatTextReplyAdmin");
            this.$store.commit(
              "setMessageGlobalAlertError",
              result.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
      this.getTextReply();
    },
    handleShowEditReply(choice) {
      if (choice) {
        this.formEditTag = {
          id: choice.id,
          type: choice.type,
          shortkeys: choice.shortkeys,
          title: choice.title,
          textReply: choice.textReply,
          replyType: choice.replyType,
          imageReply: choice.imageReply,
          imageReplyName: choice.imageReplyName,
          linkReply: choice.linkReply
        };
      }
      this.showSetReply = !this.showSetReply;
      this.getTextReply();
    },
    editReply() {
      this.$v.formEditTag.$touch();
      if (this.$v.formEditTag.$error) return;
      this.$axios
        .post(this.$chatBaseUrl + `/reply/save`, {
          brandId: this.$route.query.brandID,
          userGUID: this.$store.state.chatAdminProfile.externalId,
          id: this.formEditTag.id,
          type: this.formEditTag.type,
          title: this.formEditTag.title,
          shortkeys: this.formEditTag.shortkeys,
          textReply: this.formEditTag.textReply,
          replyType: this.formEditTag.replyType,
          imageReply: this.formEditTag.imageReply,
          imageReplyName: this.formEditTag.imageReplyName,
          linkReply: this.formEditTag.linkReply,
          updatedTime: "2021-12-29T19:50:27.602Z",
          createdTime: "2021-12-29T19:50:27.602Z"
        })
        .then(response => {
          this.$store.dispatch("getChatTextReplyAdmin");
          if (response.data.result == 1) {
            this.formEditTag.type = 1;
            this.formEditTag.id = "";
            this.formEditTag.title = "";
            this.formEditTag.shortkeys = "";
            this.formEditTag.textReply = "";
            this.formEditTag.replyType = [];
            this.formEditTag.imageReply = "";
            this.formEditTag.imageReplyName = "";
            this.formEditTag.linkReply = "";
            this.handleShowEditReply();
            this.getTextReply();
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch("getChatTextReplyAdmin");
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },
    handleLoadError() {
      this.isError = true;
      this.isBusyCon = true;
    },
    handleOpenProfileSideBar(flag) {
      document.getElementById("sidebarRightChatOrder").style.display = flag
        ? "block"
        : "none";
      this.isShowProfileOrder = flag;
      this.$store.commit("UPDATE_SHOW_CLOSE_BUTTON_CHATORDER", flag);
    },
    async handlePullMessage() {
      let dumMes = this.$store.getters.getChatMessageDummy[0];
      let messSocketTemp = this.$store.getters.getChatMessageDummy;

      messSocketTemp.shift();

      if (dumMes.isDeleted) {
        const indexUnSendMessage = this.conversation.data.findIndex(
          tem => tem.id === dumMes.message.id
        );

        if (indexUnSendMessage > -1) {
          const { message, systemType, userType } = dumMes.message;
          const updateMessage = {
            ...this.conversation.data[indexUnSendMessage],
            message,
            systemType,
            userType
          };

          Vue.set(this.conversation.data, indexUnSendMessage, updateMessage);
        }
      }
      this.$store.commit("UPDATE_CHAT_MESSAGE_DUMMY", messSocketTemp);
      if (dumMes.chatroomId === this.formatIdConversation) {
        if (dumMes.mode === 5) this.selectedTopicId = 0;
        if (dumMes.userType === "system") {
          this.adminMode =
            dumMes.mode === 3 || dumMes.mode === 4 || dumMes.topicId
              ? true
              : false;
          // this.$emit("reload-detail-list", this.formatIdConversation);
        }
        let messageRefIndx = -1;

        if (dumMes.messageRef) {
          messageRefIndx = this.conversation.data.findIndex(
            tem => dumMes.messageRef === tem.messageRef
          );
          dumMes = { ...dumMes, isWaitSend: false, isSendFail: false };
        }
        // console.log("messageRefIndx", messageRefIndx);

        let msgIndex = this.conversation.data.findIndex(
          tem => dumMes.id === tem.id
        );
        if (dumMes.systemType == this.systemType.IsSendSuccess) {
          let conversationData = [...this.conversation.data];
          if (msgIndex >= 0) {
            conversationData[msgIndex].isSendSuccess = dumMes.isSendSuccess;
            this.conversation.data = conversationData;
          }
        } else if (
          !(
            dumMes.userType == "system" &&
            dumMes.systemType == this.systemType.AssignTo &&
            this.$store.state.chatAdminProfile.externalId ==
              dumMes.toAdminExternalId
          ) ||
          (this.$store.state.chatAdminProfile.userType ==
            this.systemRolesName.SUPERADMIN &&
            dumMes.userType == "system" &&
            dumMes.systemType == this.systemType.AssignTo &&
            this.$store.state.chatAdminProfile.externalId ==
              dumMes.toAdminExternalId) ||
          (dumMes.userType == "system" && dumMes.topicId > 0)
        ) {
          let conversationData = [...this.conversation.data];
          if (messageRefIndx >= 0) {
            conversationData[messageRefIndx] = { ...dumMes };
            this.conversation.data = conversationData;
          } else {
            let index = -1;
            if (dumMes.systemType == this.systemType.AssignTo) {
              index = await conversationData.findIndex(
                e => e.id === dumMes.roomDetail?.lastMessageId
              );
              console.log("t pull msg i --->", index);
              if (index == -1) {
                dumMes.id = dumMes.roomDetail?.lastMessageId;
                this.conversation.data = await [dumMes, ...conversationData];
              }
            } else if (!dumMes.id) {
              console.log("!id pull msg i --->", index);
              this.conversation.data = await [dumMes, ...conversationData];
            } else {
              index = await conversationData.findIndex(e => e.id === dumMes.id);
              console.log("e pull msg i --->", index);
              if (index == -1) {
                this.conversation.data = await [dumMes, ...conversationData];
              }
            }
          }
        }
        if (!this.selectedTopicId) await this.handleDisable();
        // let dumIndx = this.dummyMessageList.findIndex(
        //   (tem) => dumMes.message.id === tem.offlineId
        // );
        // if (dumIndx >= 0) {
        //   this.dummyMessageList.splice(dumIndx, 1);
        // }
        setTimeout(() => {
          let objDiv = document.getElementById("chatMsgCardBody");
          objDiv.scrollTop = objDiv.scrollHeight;
          if (this.$store.getters.getChatMessageDummy.length > 0) {
            this.handlePullMessage();
          }
        }, 100);
      }
    },
    toggleSideBarSetTextReply() {
      if (this.showSetReply) {
        this.showSetReply = false;
      }
      this.$emit("toggleSideBarSetTextReply");
    },
    toggleSideBarChatHistory() {},
    checkTranslate() {
      this.isLoadingTranslate = true;
      clearTimeout(this.timeCallTranslate);
      this.timeCallTranslate = setTimeout(() => {
        this.getTranslate();
      }, 3000);
    },
    getTranslate() {
      if (
        this.textInput &&
        this.textInput.trim() != "" &&
        this.textInput.trim() != "/"
      ) {
        this.isLoadingTranslate = true;
        this.textTranslated = "";
        fetch(`${this.$chatBaseUrl}/translate/${this.textInput}`, {
          method: "GET",
          headers: new Headers(this.$headersChat)
        })
          .then(response => response.json())
          .then(async result => {
            this.isLoadingTranslate = false;
            if (result.result == 1) {
              this.textTranslated = result.detail;
            }
          })
          .catch(error => {
            console.log("error", error);
            this.isLoadingTranslate = false;
          });
      } else {
        this.isLoadingTranslate = false;
      }
    },
    async handleConfirmTextReply(choice) {
      if (this.selectedBrand.isPopupComfirmChat) {
        this.$refs.ChatboxModalConfirmTextReply.show(choice);
      } else {
        this.clickSelectTextReply(choice);
      }
    },
    async clickSelectTextReply(choice) {
      if (choice.replyType.indexOf(1) > -1) {
        let dataSend = {
          text: choice.textReply,
          imageUrl: "",
          attachments: null,
          isReply: true
        };
        await this.sendMessage(dataSend, 1);
      }
      if (choice.replyType.indexOf(2) > -1) {
        let dataSend = {
          text: "",
          imageUrl: choice.imageReply,
          attachments: null,
          isReply: true
        };
        await this.sendMessage(dataSend, 4);
      }
      if (choice.replyType.indexOf(4) > -1) {
        let dataSend = {
          text: choice.linkReply,
          imageUrl: "",
          attachments: null,
          isReply: true
        };
        await this.sendMessage(dataSend, 1);
      }

      this.choiceReply = [];
      const { translationEnable } = this.selectedBrand;
      if (this.$store.state.isChatTranslate && translationEnable) {
        this.isShowTranslate = true;
        this.checkTranslate();
      }
    },
    handleShowModalAssignTo() {
      this.$refs.ChatBoxModalAssignTo.show();
    },
    handleShowSideberFilterHistory() {
      this.setDisplaySearch(false);
      this.$refs.ChatSideBarHistory.show();
    },
    handleSelectedStaff() {},
    handleChangeReplyType(replyType, tagId) {
      if (tagId == 0) {
        let index = this.formTag.replyType.indexOf(replyType);
        if (index < 0) this.formTag.replyType.push(replyType);
        else
          this.formTag.replyType = this.formTag.replyType.filter(
            ele => ele != replyType
          );
      } else {
        let index = this.formEditTag.replyType.indexOf(replyType);
        if (index < 0) this.formEditTag.replyType.push(replyType);
        else
          this.formEditTag.replyType = this.formEditTag.replyType.filter(
            ele => ele != replyType
          );
      }
    },
    onImageChange(value, id) {
      let keyName = "formEditTag";
      if (id == 0) keyName = "formTag";
      this[keyName].imageReply = "";
      this[keyName].imageReplyName = "";
      if (value) {
        this[keyName].imageReplyName = value.name; //`${value.name}.${value.type.split("/").pop(-1)}`; //value.name;
        var reader = new FileReader();
        reader.readAsDataURL(value);
        reader.onload = () => {
          this[keyName].imageReply = reader.result;
        };
      }
    },
    onDeleteImage(id) {
      let keyName = "formEditTag";
      if (id == 0) keyName = "formTag";
      this[keyName].imageReply = "";
      this[keyName].imageReplyName = "";
    },
    setActivePanel(number) {
      if (this.isMobile) this.$store.dispatch("setActivePanel", number);
    },
    async handleTopic(id, item = null, isEndChat = false, mode = 0) {
      // this.$store.dispatch("setIsLoading", true);
      let lastMessage = this.conversation.data[0];
      if (!isEndChat) this.selectedTopicId = id;

      let messageRef = `${this.userChatInfo.id}-${moment().valueOf()}`;
      let dataSend = {
        text: "This conversation was added to the topic ",
        topicId: id,
        topicName: item.name,
        adminName: `${this.$store.state.chatAdminProfile.firstname} ${this.$store.state.chatAdminProfile.lastname}`,
        systemType: this.systemType.ChangeTopic,
        userType: "system"
      };
      await this.addWaitingSend(dataSend, 1, messageRef);

      let body = {
        chatRoomId: this.formatIdConversation,
        topicId: id,
        externalId: this.$store.state.chatAdminProfile.externalId,
        messageRef: messageRef
      };
      await fetch(`${this.$chatBaseUrl}/chatroom/topic/update`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          if (result.result) {
            await this.handleResponseMessageRef(messageRef, result, "topic");
            if (isEndChat) {
              await this.btnEndChat(mode);
            } else {
              if (
                (lastMessage.userType == "admin" &&
                  lastMessage.createdBy &&
                  this.$store.state.chatAdminProfile.id !=
                    lastMessage.createdBy) ||
                (lastMessage.userType == "system" &&
                  lastMessage.toAdminExternalId &&
                  this.$store.state.chatAdminProfile.externalId !=
                    lastMessage.toAdminExternalId) ||
                (lastMessage.userType == "user" &&
                  (lastMessage.roomDetail?.lastAdminId == "" ||
                    lastMessage.roomDetail?.lastAdminId !=
                      this.$store.state.chatAdminProfile.externalId))
              ) {
                await this.assignToStaff(
                  this.$store.state.chatAdminProfile.externalId
                );
              }
            }
          } else {
            await this.handleResponseMessageRef(messageRef, result, "topic");
            this.$store.commit("setMessageGlobalAlertError", result.message);
            this.$store.commit("setShowGlobalAlertError", true);
            this.$store.dispatch("setIsLoading", false);
          }
        })
        .catch(error => {
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
          console.log("error", error);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    handleDisable() {
      let lastMessage = this.conversation.data[0];
      this.selectedTopicId = lastMessage.topicId || 0;
      this.disabledTopic = lastMessage.mode == 5;
      this.disabledAssignTo = lastMessage.mode == 5;
    },
    async getNotes() {
      await this.$store.dispatch("getAgentNoteType", {
        chatroomId: this.formatIdConversation,
        type: 1
      });
      await this.$store.dispatch("getAgentNoteType", {
        chatroomId: this.formatIdConversation,
        type: 2
      });
    },
    copyClipboard() {
      let name = this.$store.state.displayNameCurrentChat
        ? this.$store.state.displayNameCurrentChat
        : this.otherInfo.displayname;
      navigator.clipboard
        .writeText(name)
        .then(() => {
          this.tooltipText = "คัดลอกสำเร็จ";
        })
        .catch(err => {
          console.error("Error in copying text: ", err);
        });
    },
    outCopyClipboard() {
      this.tooltipText = "คัดลอก";
    }
  },
  async created() {
    this.isShownChatSideBarHistory = false;
    this.topicsOption = this.topicList;
    this.$store.commit("UPDATE_AGENT_TICKET_FLAG_REGISTER", false);
    this.$store.commit("UPDATE_AGENT_TICKET_URL_CREATE", "");
    await this.$store.commit("UPDATE_AGENT_NOTE_EMPTY");
    await this.prepareData();
    await this.handleDisable();
    await this.getNotes();
    // this.$store.commit("setShowProfileTab", false);
    this.isShowProfileTab = this.$store.getters.getIsShowProfileTab;
    this.getApiTrigger();
    if (this.isVisibleLeaveBtn === true) {
      this.isPrivateChat = true;
    }
  },
  watch: {
    textSearchTopics() {
      this.onSearchTopics();
    },
    textInput() {
      if (this.textInput.includes("@")) {
        const match = this.textInput.match(/@([^\s]*)$/);
        if (match && match[1] !== "") {
          const searchQuery = match[1];

          this.getStaff(searchQuery);
        }
      }
      if (this.adminMention.length > 0) {
        this.formatTextInputWithMentions();
      }
    },
    isPrivateChat(val) {
      if (val === false && this.isShowMention) {
        this.isShowMention = false;
        this.adminMention = [];
      }
    },
    "$store.state.isLoadChatMessageList": function() {
      if (this.$store.state.isLoadChatMessageList) {
        this.handlePullMessage();
      }
    },
    "$store.state.isShowCloseButtonChatOrder": function() {
      if (!this.$store.state.isShowCloseButtonChatOrder) {
        this.handleOpenProfileSideBar(false);
        if (!this.isError) {
          setTimeout(() => {
            let objDiv = document.getElementById("chatMsgCardBody");
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 100);
        }
      }
    }
  },
  computed: {
    chatAddminProfileId() {
      return this.$store.state.chatAdminProfile.id;
    },
    isVisibleLeaveBtn() {
      return (
        this.conversationInfo.lastAdminId !=
          this.$store.state.chatAdminProfile.externalId &&
        this.conversationInfo.adminMention.includes(this.chatAddminProfileId)
      );
    },
    stateUnresolveNoteSubject() {
      return this.unresolveNoteSubject.length > 0;
    },
    ...mapGetters({
      selectedUserChatList: "getSelectedUserChatList",
      displayCheckboxSelectUserChat: "getDisplayCheckboxSelectUserChat",
      isMobile: "getIsMobile",
      profileNote: "getAgentNote",
      topicList: "getConversationTopicList",
      selectedBrand: "getSelectedBrand"
    }),
    chatSocialType() {
      let index = this.$store.getters.getChatSocialList.findIndex(
        t => t.id == this.socialType
      );
      if (index >= 0) {
        let social = this.$store.getters.getChatSocialList[index];
        return social;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-topics-wrapper {
  padding: 0.5rem;
  position: sticky;
  background-color: #fff;
  bottom: 0;
  z-index: 10;
  border-top: #c1c1c1 1px solid;
}
.no-topics {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
}
.text-no-topics {
  width: 340px;
  text-align: center;
  color: #dbdbdb;
  font-weight: bold;
}
.text-topics {
  text-wrap: auto;
  width: 300px;
}
.chat-box-mention {
  opacity: 0.8;
  z-index: 4;
  min-height: 40%;
  max-height: 140px;
  overflow: scroll;
  scroll-behavior: smooth;
  :hover {
    cursor: pointer;
    background-color: #eeeeee;
    opacity: 0.8;
  }
}
[contentEditable="true"]:empty:not(:focus):before {
  content: attr(data-text);
  color: #dbdbdb;
  pointer-events: none;
}
.editor {
  width: 400px;
  height: 100px;
  padding: 10px;
  color: #444;
  background-color: white;
  font-size: 14px;
  font-family: monospace;
  overflow-y: auto;
  white-space: pre-wrap;
}

.other {
  color: white;
}
.topnav {
  overflow: hidden;
  border-bottom: #d8dbe0 1px solid;
  border-top: #d8dbe0 1px solid;
}

.topnav a {
  float: left;
  color: #575757;
  text-align: center;
  padding: 4px 16px;
  text-decoration: none;
  cursor: pointer;
}

.topnav a:hover {
  color: #832abf;
}

.topnav a.active {
  color: #832abf;
  border-bottom: #832abf 5px solid;
}
.panel {
  height: 100%;
  margin: 0;
}
.card-box {
  background-color: #f6f6f6;
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
}
.card-box-header {
  background-color: #fff;
  padding: 0.4rem 1rem;
  // font-family: "Kanit-Medium";
}
.card-box-header-profile {
  position: relative;
  background-color: #fff;
  min-height: 62px;
  > div {
    min-height: 42px;
  }
}
.card-box-header-profile.show-search {
  min-height: 118px;
}
.wrpa-msg_card_body {
  position: relative;
  height: 100%;
  padding-left: 5px;
  display: contents;
}
.bd-highlight {
  z-index: 1;
  position: relative;
}
.msg_card_body {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px 5px 3px 5px;
}
.msg_card_body::-webkit-scrollbar {
  width: 0.35em;
}
.msg_card_body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(177, 175, 175, 0.3);
}
.msg_card_body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  outline: 1px solid #c1c1c1;
}
.wrap-card-box-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #d8dbe0;
}

.container {
  align-content: center;
}
.type_msg {
  background-color: inherit !important;
  color: #575757 !important;
  overflow-x: hidden;
  resize: vertical;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 75px;
  max-height: 200px;
}
.type_msg_private {
  background-color: #fff1de !important;
  color: #575757 !important;
  overflow-x: hidden;
  resize: vertical;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 75px;
  max-height: 200px;
}
.blue-text {
  color: blue !important; /* ตัวอักษรจะเปลี่ยนเป็นสีฟ้า */
}
.type_msg:focus {
  box-shadow: rgba(0, 0, 0, 0.164) !important;
  outline: 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.164) !important;
}
.type_msg::placeholder {
  color: #dbdbdb;
}
.attach_btn {
  border-radius: 22px !important;
  background-color: inherit !important;
  border: 0 !important;
  // color: #f3591f !important;
  cursor: pointer;
  padding: 0 0.25rem;
}
.send_btn {
  background-color: inherit !important;
  border: 0 !important;
  // color: #f3591f !important;
  // cursor: pointer;
}
.user_img {
  height: 40px;
  width: 40px;
  border: 1.5px solid #dbdbdb;
}
.user_img_display {
  width: 100%;
  padding-bottom: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}
.img_back {
  position: relative;
  height: 44px;
  width: auto;
}
.img_back_icon {
  height: 100%;
  width: 15px;
  // color: #f3591f;
}
.img_cont {
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 15px;
}
.img_cont img {
  background-color: #fff;
}
.img_cont svg {
  padding: 5px;
}
.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.offline {
  background-color: #c23616 !important;
}
.user_info {
  margin-top: auto;
  margin-bottom: auto;
  width: 45%;
  max-width: 45%;
  .name {
    // width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  span {
    font-size: 16px;
    font-weight: bold;
  }
  .tag-social span {
    font-weight: normal;
  }
}

.video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.msg_head {
  position: relative;
  border-bottom: 1px solid #e6e7eb;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

::v-deep .dropdown-menu {
  height: fit-content !important;
  max-height: 600px;
  overflow-y: auto !important;

  transform: none !important;
  top: auto !important;
  left: auto !important;
  will-change: unset !important;
}

::v-deep .dropdown-item.active,
::v-deep .dropdown-item:active {
  background-color: #832abf !important;
}
.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.action_menu ul li i {
  padding-right: 10px;
}
.action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-radius: 22px;
}
.form-control {
  border: 0 solid !important;
}
.reload-chat,
.loading-prepare {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.loading-message {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: none;
}
.spinner-border {
  width: 20px;
  height: 20px;
}
.panel-wellcome-chat {
  padding-top: 20%;
  align-items: center;
  justify-content: center;
  // height: 75vh;
  height: 100%;
  width: 100%;
}
.panel-loading {
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.panel-wellcome-chat p {
  color: rgb(43, 43, 43);
}
.selected-chat {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .selected-count {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0;
  }
  .detail-count {
    margin-bottom: 0;
  }
  .wrap-img-agent {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    margin: 10px 0;
    .img-agent-size {
      height: 40px !important;
      width: 40px !important;
      padding: 0 !important;
      cursor: pointer;
    }
    .img-agent {
      background-color: #aaa5e4;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(113, 96, 196, 0.27);
      font-size: 20px;
      margin-left: -8px;
      span {
        margin: auto 0;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
.wrap-other-chat-img {
  height: 100px;
  width: 100px;
}
.other-chat-img {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

::v-deep .b-sidebar-body,
::v-deep .b-sidebar {
  height: 100%;
}

.panel-close-profile-order {
  position: fixed;
  top: 2px;
  right: 5px;
  z-index: 1021;
}
.list-reply {
  overflow: hidden;
  overflow-y: auto;
  max-height: 200px;
  border-radius: 0rem;
  .text-wrap {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #598e74;
    display: -webkit-box;
    min-height: 1.2rem;
    line-height: 1.2rem;
    max-height: 2.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.edit-reply {
  min-height: 190px;
  border-radius: 0rem;
  border: 1px solid rgba(0, 0, 21, 0.125);
}
.list-item-reply:hover {
  background-color: #ebedef;
  cursor: pointer;
}
.menu-reply {
  font-size: 12px;
}
.menu-reply:hover {
  cursor: pointer;
  text-decoration: underline;
}
.back-edit-reply:hover {
  cursor: pointer;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0rem;
}
.reply-key {
  word-wrap: break-word;
}
.f-size-16 {
  font-size: 16px;
}

.btn-sumbit-sesolve {
  background-color: #832abf !important;
  color: #fff !important;
}

#name-input-modal-unresolve {
  border-color: #d8dbe0 !important;
  border: 1px solid !important;
}

.text-error-api-trigger {
  font-size: 11px;
}

.tag-social {
  display: flex;
  flex-flow: row;
}

.tag-social img {
  height: 16px;
  width: auto;
}

.tag-social span {
  font-size: 12px;
  color: #9c9c9c;
  margin: auto 0 auto 5px;
}
.btn-transfer-agent {
  border-radius: 33px;
  padding: 0px 5px;
  font-size: 11px;
}
.btn-groups {
  button {
    background-color: #fff;
    color: #d6d6d6;
    border-color: #dbdbdb;
    margin-left: 0px !important;
  }
  button:nth-child(2) {
    border-left-color: #dbdbdb !important;
    border-right-color: #dbdbdb !important;
  }
  button.active {
    border-color: rgb(113, 96, 196);
    background-color: rgb(113, 96, 196);
    color: #fff;
  }
  button:hover {
    border-color: #d6d6d6;
  }
}
.badge-badge-private {
  color: #fff;
  background-color: #832abf;
  border: 1px solid #832abf;
}
.badge-badge-public {
  background-color: #fff;
  color: #832abf;
  border: 1px solid #832abf;
  font-size: 10px;
}
.note-active {
  color: #832abf !important;
}
.public-note {
  font-weight: bold;
  padding: 0 1rem !important;
  margin-top: 0.5rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  line-height: 1rem;
  min-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-weight: normal;
    color: #832abf;
  }
}
.panel-wrap-card-box-footer {
  position: relative;
}
.disabled-panel {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6) !important;
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}
::v-deep .wrap-footer-reply {
  .btn-main-primary,
  .btn-outline-primary {
    min-width: unset;
  }
}
.btn-search {
  padding: 0;
  color: #832abf;
  font-weight: lighter;
  svg {
    font-size: 12px;
  }
}
.panel-header-profile {
  padding: 10px 13px;
}
.panel-search {
  // position: absolute;
  // left: 0;
  // bottom: -55px;
  // z-index: 500;
  z-index: 1;
  position: relative;
  display: flex !important;
  align-items: center !important;
  background-color: #fff;
  width: 100%;
  padding: 10px 13px;
  border-top: 1px solid #d8dbe0 !important;
  .div-input {
    margin-bottom: 0;
    width: 100%;
  }
  .total {
    margin-left: 5px;
  }
  .btn-arrow {
    padding: 0;
    color: #2c3e50;
  }
  svg {
    color: #2c3e50;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
  }
  .arrow.disabled {
    color: #dbdbdb !important;
  }
}
::v-deep .input-with-btn-icon {
  .custom-input {
    border-radius: 50rem 0px 0px 50rem !important;
    border-right: 0 !important;
  }
  .custom-input:focus {
    border-color: #dbdbdb;
  }
  .btn-icon {
    border-radius: 0px 50rem 50rem 0px;
  }
  .btn-icon:hover,
  .btn-icon:active {
    background-color: transparent;
  }
}
::v-deep mark {
  background-color: transparent !important;
  padding: 2px 0;
}
::v-deep .show-mark {
  mark {
    background-color: #ffeb3b !important;
  }
  mark.active {
    background-color: #ffc107 !important;
  }
}
::v-deep .display-time {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px !important;
  max-height: unset !important;
  display: block !important;
  p {
    margin-bottom: 0;
  }
}
.btn-copy-clipboard {
  padding: 0;
  color: #832abf;
  cursor: pointer;
  font-size: 12px;
}
@media (max-width: 767.98px) {
  .no-topics {
    min-height: 40px;
  }

  .text-no-topics {
    width: 240px;
    text-align: center;
  }
  ::v-deep .dropdown-menu {
    max-height: 400px;
    overflow-y: auto !important;
  }
  .text-topics {
    width: 200px;
  }
  .panel {
    position: relative;
    top: 40px;
    height: calc(100dvh - 40px) !important;
  }
  .mobile-panel {
    top: 0px !important;
  }
  .card-box-header {
    padding: 0.4rem 1.25rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .card-box-header-profile {
    min-height: 52px;
  }
  .card-box-header-profile .img_cont,
  .card-box-header-profile .user_img {
    height: 40px;
    width: 40px;
  }
  .img_back {
    height: 30px;
  }
  .user_img {
    height: 30px;
    width: 30px;
  }
  .img_cont {
    height: 30px;
    width: 30px;
  }
  .user_info {
    width: 25%;
    max-width: 25%;
  }
  .user_info span {
    font-size: 14px;
  }
  .wrpa-msg_card_body {
    top: 104px;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
  }
  .msg_card_body {
    position: relative;
    height: calc(100% - 52px - 170px);
    padding: 10px 5px 3px 5px;
  }
  .msg_card_body.have-public {
    height: calc(100% - 52px - 125px - 55px);
  }
  .msg_card_body-is-mobile-app {
    height: calc(100% - 52px - 125px + 16px) !important;
  }
  .msg_card_body-is-mobile-app.have-public {
    height: calc(100% - 52px - 125px + 16px - 20px - 16px) !important;
  }
  .panel-wrap-card-box-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
  .wrap-card-box-footer {
    position: relative;
    width: 100% !important;
    z-index: 1020;
    background-color: #fff;
  }
  .disabled-panel {
    z-index: 1021;
  }
  .ChatOrderPanel {
    // height: 100vh;
    top: 0px;
    // height: calc(100vh - 51px) !important;
  }
}
</style>
